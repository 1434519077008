<template>
  <div class="loader-wave-container">
    <div
      class="loader-wave"
      :style="{
        backgroundImage:
          'url(' + require('@/assets/images/logo_icon.svg') + ')',
      }"
    ></div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.loader-wave-container {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  z-index: 999;
}

.loader-wave {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  width: 120px;
  height: 120px;
  padding: 0px 15px;
  background-position: center;
  background-size: cover;
  box-sizing: border-box;
  border-radius: 50%;
  animation: pulse 1.5s infinite;
  transition: 0.3s;
}
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(#000, 0);
    transform: scale(0.9);
  }
  40% {
    box-shadow: 0 0 0 50px rgba(#000, 0.05);
    transform: scale(1.1);
  }
  100% {
    box-shadow: 0 0 0 50px rgba(#000, 0);
    transform: scale(0.9);
  }
}

.loader-wave div {
  display: inline-block;
  width: 4px;
  background: #fff;
  animation: loader-wave 1.2s linear infinite;
}

.loader-wave div:nth-child(1) {
  animation-delay: -0.24s;
  height: 40px;
}

.loader-wave div:nth-child(2) {
  animation-delay: -0.12s;
  height: 26px;
}

.loader-wave div:nth-child(3) {
  animation-delay: -0.24s;
  height: 40px;
}

.loader-wave div:nth-child(4) {
  animation-delay: -0.12s;
  height: 40px;
}

.loader-wave div:nth-child(5) {
  animation-delay: -0.24s;
  height: 26px;
}

.loader-wave div:nth-child(6) {
  animation-delay: -0.12s;
  height: 40px;
}

.loader-wave div:nth-child(7) {
  animation-delay: -0.24s;
  height: 33px;
  margin-top: 3px;
}

@keyframes loader-wave {
  0% {
    transform: scaleY(1);
  }

  30% {
    transform: scaleY(0.8);
  }

  60% {
    transform: scaleY(1.3);
  }

  100% {
    transform: scaleY(1);
  }
}
</style>
